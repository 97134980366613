export function createGetterSetter (namespace, key, isInt = false) {
  return {
    get () {
      return this.$store.state[namespace][key]
    },
    set (value) {
      if (isInt && value !== '') {
        value = parseInt(value)
      }
      const data = {
        key: key,
        value: value
      }
      this.$store.commit(`${namespace}/updateState`, data)
    }
  }
}
