const FORM_STATE = [
  'estimate',
  'econfirm',
  'bookdata',
  'bconfirm',
  'questionary',
  'confirm',
  'thanks'
]

const state = () => ({
  status: 0,
  master: {
    paper: [],
    relpaper: []
  },
  calc: null,
  environment: 'production'
})

const getters = {
  formstate: (state) => {
    const labels = FORM_STATE

    const current = state.status
    if (typeof labels[current] === 'undefined') {
      return null
    }
    return labels[current]
  }
}

const mutations = {
  formNext (state) {
    const current = state.status
    const next = current + 1
    if (typeof FORM_STATE[next] === 'undefined') {
      return null
    }

    state.status = next
  },
  formBack (state) {
    const current = state.status
    const preview = current - 1
    if (typeof FORM_STATE[preview] === 'undefined') {
      return null
    }

    state.status = preview
  },
  setCalculator (state, obj) {
    state.calc = obj
  },
  setMasterPaper (state, datas) {
    state.master.paper = datas
    state.calc.setMasterPaper(datas)
  },
  setMasterRelpaper (state, datas) {
    state.master.relpaper = datas
  },
  setTableAData (state, datas) {
    state.calc.setTableA(datas)
  },
  setTableBData (state, datas) {
    state.calc.setTableB(datas)
  },
  setTableEData (state, datas) {
    state.calc.setTableE(datas)
  },
  setEnvironment (state, environment) {
    state.environment = environment
  }
}

const actions = {
  fetchMasterTables (context) {
    fetch('./js/tables/a.json')
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('データが取得できませんでした。再読み込みしてください。')
      })
      .then(json => {
        context.commit('setTableAData', json)
      })

    fetch('./js/tables/b.json')
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('データが取得できませんでした。再読み込みしてください。')
      })
      .then(json => {
        context.commit('setTableBData', json)
      })

    fetch('./js/tables/e.json')
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('データが取得できませんでした。再読み込みしてください。')
      })
      .then(json => {
        context.commit('setTableEData', json)
      })
  },
  fetchMasterPapers (context) {
    fetch('./js/tables/master-paper.json')
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('データが取得できませんでした。再読み込みしてください。')
      })
      .then(json => {
        context.commit('setMasterPaper', json)
      })

    fetch('./js/tables/rel-genre-paper.json')
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('データが取得できませんでした。再読み込みしてください。')
      })
      .then(json => {
        context.commit('setMasterRelpaper', json)
      })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
