<template>
  <div class="autoprice__confirm-table">

    <div class="autoprice__confirm-result">
      <h2 class="autoprice__confirm-price">
        <strong>お見積り結果</strong>
        <b>お見積り金額</b><em>{{ resultValue.toLocaleString() }}円</em><small>（税込）</small>
      </h2>
      <p class="autoprice__confirm-lead">
        <strong>ー 重要な特記事項 ー</strong>
        原稿や企画をご提出いただき担当者がついて進行する場合は、上記の額が増減することがあります。
      </p>
    </div>

    <!-- 書籍　基本仕様 -->
    <div class="confirm_wrap" >
      <h2 class="autoprice__confirm-tablehead">書籍　基本仕様</h2>
      <input id="book_spec" type="checkbox" class="openbutton"><label for="book_spec"></label>
      <div id="spec_content" class="spec_content">
        <div class="autoprice__confirm-item">
          <label>書籍ジャンル</label>
          <em v-if="genre === ''"></em>
          <em v-else>{{ genre }}</em>
          <span v-if="typeof subtotal.basicfee === 'undefined'"></span>
          <span v-else>{{ subtotal.basicfee.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>本のサイズ</label>
          <em v-if="booksize === ''"></em>
          <em v-else>{{ booksize.name }}<small>{{ booksize.desc }}</small></em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>製本の仕様</label>
          <em>{{ bookbinding }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>ページ数</label>
          <em v-if="pageCount === ''"></em>
          <em v-else>{{ pageCount }}ページ</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>制作部数（冊数）</label>
          <em>{{ copies }}部</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>受取部数</label>
          <em v-if="receiveCopies === ''"></em>
          <em v-else>{{ receiveCopies }}部</em>
          <span></span>
        </div>

        <!-- 書籍　印刷仕様 -->

        <h2 class="autoprice__confirm-tablehead">書籍　印刷仕様</h2>
        <div class="autoprice__confirm-item">
          <label>ブックカバー</label>
          <em>{{ bookcover }}</em>
          <span v-if="coverPrinting === ''"></span>
          <span v-else>{{ coverPrinting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>ブックカバー用紙</label>
          <em>{{ bookcoverPaper }}</em>
          <span v-if="typeof subtotal.coverPaper === 'undefined'"></span>
          <span v-else>{{ subtotal.coverPaper.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>カバー帯</label>
          <em>{{ coverbelt }}</em>
          <span v-if="coverBeltPrinting === ''"></span>
          <span v-else>{{ coverBeltPrinting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>カバー帯用紙</label>
          <em></em>
          <span v-if="typeof subtotal.coverBeltPaper === 'undefined'"></span>
          <span v-else>{{ subtotal.coverBeltPaper.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>表紙</label>
          <em></em>
          <span v-if="frontCoverPrinting === ''"></span>
          <span v-else>{{ frontCoverPrinting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>表紙用紙</label>
          <em>{{ frontcoverPaper }}</em>
          <span v-if="typeof subtotal.frontCoverPaper === 'undefined'"></span>
          <span v-else>{{ subtotal.frontCoverPaper.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>カバーや表紙の加工</label>
          <em>{{ treatment }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>本文カラー</label>
          <em>{{ textcolor }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>本文</label>
          <em></em>
          <span v-if="bodyPrinting === ''"></span>
          <span v-else>{{ bodyPrinting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>本文用紙</label>
          <em>{{ bodyPaper }}</em>
          <span v-if="typeof subtotal.bodyPaper === 'undefined'"></span>
          <span v-else>{{ subtotal.bodyPaper.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>見返し</label>
          <em>{{ mikaeshi }}</em>
          <span v-if="mikaeshiPrinting === ''"></span>
          <span v-else>{{ mikaeshiPrinting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>見返し用紙</label>
          <em>{{ mikaeshiPaper }}</em>
          <span v-if="typeof subtotal.mikaeshiPaper === 'undefined'"></span>
          <span v-else>{{ subtotal.mikaeshiPaper.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>製本</label>
          <em></em>
          <span v-if="typeof subtotal.bookBinding === 'undefined'"></span>
          <span v-else>{{ subtotal.bookBinding.toLocaleString() }}円</span>
        </div>

        <!-- プリプレス（制作） -->

        <h2 class="autoprice__confirm-tablehead">プリプレス（制作）</h2>
        <div class="autoprice__confirm-item">
          <label>原稿の特徴</label>
          <em>{{ bookfeature }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>原稿の状況</label>
          <em>{{ progress }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>企画を依頼する</label>
          <em>{{ planning }}</em>
          <span v-if="typeof subtotal.planning === 'undefined'"></span>
          <span v-else>{{ subtotal.planning.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>編集を依頼する</label>
          <em>{{ editing }}</em>
          <span v-if="typeof subtotal.editing === 'undefined'"></span>
          <span v-else>{{ subtotal.editing.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>ライティングの依頼</label>
          <em>{{ writing }}</em>
          <span v-if="typeof subtotal.writing === 'undefined'"></span>
          <span v-else>{{ subtotal.writing.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>リライトの依頼</label>
          <em>{{ rewriting }}</em>
          <span v-if="typeof subtotal.rewriting === 'undefined'"></span>
          <span v-else>{{ subtotal.rewriting.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>テキスト校正</label>
          <em>{{ proofreading }}</em>
          <span v-if="typeof subtotal.proofreading === 'undefined'"></span>
          <span v-else>{{ subtotal.proofreading.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>ブックカバーデザイン</label>
          <em></em>
          <span v-if="typeof subtotal.coverdesign === 'undefined'"></span>
          <span v-else>{{ subtotal.coverdesign.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>表紙デザイン</label>
          <em></em>
          <span v-if="typeof subtotal.frontcoverdesign === 'undefined'"></span>
          <span v-else>{{ subtotal.frontcoverdesign.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>カバー帯デザイン</label>
          <em></em>
          <span v-if="typeof subtotal.coverbeltdesign === 'undefined'"></span>
          <span v-else>{{ subtotal.coverbeltdesign.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>DTP</label>
          <em></em>
          <span v-if="typeof subtotal.dtp === 'undefined'"></span>
          <span v-else>{{ subtotal.dtp.toLocaleString() }}円</span>
        </div>

        <!-- 作品＆創作の状況 -->

        <h2 class="autoprice__confirm-tablehead">作品＆創作の状況</h2>
        <div class="autoprice__confirm-item">
          <label>原稿データ確認</label>
          <em>{{ datatype }}</em>
          <span></span>
        </div>
        <div class="autoprice__confirm-item">
          <label>装丁デザイン</label>
          <em>{{ binding }}</em>
          <span></span>
        </div>

        <!-- 販売方法 -->

        <h2 class="autoprice__confirm-tablehead">販売方法</h2>
        <div class="autoprice__confirm-item">
          <label>流通について</label>
          <em>{{ distribution }}</em>
          <span v-if="typeof subtotal.distribution === 'undefined'"></span>
          <span v-else>{{ subtotal.distribution.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>流通パッケージ</label>
          <em>{{ distributionPackage }}</em>
          <span v-if="typeof subtotal.promotion === 'undefined'"></span>
          <span v-else>{{ subtotal.promotion.toLocaleString() }}円</span>
        </div>

        <!-- オプション -->

        <h2 class="autoprice__confirm-tablehead">オプション</h2>
        <div class="autoprice__confirm-item">
          <label>箔押し加工</label>
          <em>{{ hakuoshi }}</em>
          <span v-if="typeof subtotal.hakuoshi === 'undefined'"></span>
          <span v-else>{{ subtotal.hakuoshi.toLocaleString() }}円</span>
        </div>
        <div class="autoprice__confirm-item">
          <label>スキャンを依頼する</label>
          <em>{{ scanCount }}点</em>
          <span v-if="typeof subtotal.scaning === 'undefined'"></span>
          <span v-else>{{ subtotal.scaning.toLocaleString() }}円</span>
        </div>

        <!-- 製作費用合計 -->

        <h2 class="autoprice__confirm-tablehead"></h2>
        <div class="autoprice__confirm-item">
          <label>製作費用合計</label>
          <em></em>
          <span>{{ seisakugokei.toLocaleString() }}円</span>
        </div>

        <!-- 価格高騰対策費 -->

        <h2 class="autoprice__confirm-tablehead">価格高騰対策費</h2>

        <div class="autoprice__confirm-item">
          <label>価格高騰対策費 (25%)</label>
          <em>用紙・印刷製本・配送・管理・その他費用高騰による費用です。（2023年11月より実施）</em>
          <span>{{ koutoufee.toLocaleString() }}円</span>
        </div>

        <!-- 小計・消費税 -->

        <h2 class="autoprice__confirm-tablehead">小計・消費税</h2>
        <div class="autoprice__confirm-item">
          <label>小計</label>
          <em></em>
          <span>{{ taxtarget.toLocaleString() }}円</span>
        </div>

        <div class="autoprice__confirm-item">
          <label>消費税 ({{ tax }}%)</label>
          <em></em>
          <span>{{ taxfee.toLocaleString() }}円</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import estimateData from '../../store/modules/formstructs/estimate.js'
import bookSizeItems from '../estimate/bookbasic/data/booksizeitems.js'

const computed = {}
for (const type in estimateData) {
  for (const key in estimateData[type]) {
    computed[key] = function () {
      const formset = this.$store.getters['estimate/getFormsetWithLabel']
      return formset[key]
    }
  }
}

Object.assign(computed, {
  booksize: function () {
    const id = this.$store.state.estimate.booksize
    const result = bookSizeItems.filter(item => item.value === id)
    if (result.length > 0) {
      return result[0]
    }
    return ''
  },
  resultValue: function () {
    const result = this.taxtarget + this.taxfee
    return result
  },
  subtotal: function () {
    const reciepts = this.$store.state.estimate.reciept
    const result = {}
    for (const index in reciepts) {
      const row = reciepts[index]
      const key = row.id
      result[key] = row.value
    }

    return result
  },
  seisakugokei: function () {
    const result = this.$store.getters['estimate/seisakugokei']
    return result
  },
  koutoufee: function () {
    const result = this.$store.getters['estimate/kakakukoutoufee']
    return result
  },
  taxtarget: function () {
    const result = this.$store.getters['estimate/taxtarget']
    return result
  },
  tax: function () {
    const result = this.$store.getters['estimate/tax']
    return result
  },
  taxfee: function () {
    const result = this.$store.getters['estimate/taxfee']
    return result
  },
  bodyPrinting: function () {
    if (typeof this.subtotal.bodyPrinting === 'undefined') {
      return ''
    }
    let result = this.subtotal.bodyPrinting + this.subtotal.bodyPrintingPlate
    result += this.subtotal.bodyProofreading
    return result
  },
  coverPrinting: function () {
    if (typeof this.subtotal.coverPrinting === 'undefined') {
      return ''
    }
    let result = this.subtotal.coverPrintingPlate + this.subtotal.coverPrinting
    result += this.subtotal.coverProofreading
    return result
  },
  coverBeltPrinting: function () {
    if (typeof this.subtotal.coverBeltPrinting === 'undefined') {
      return ''
    }
    let result = this.subtotal.coverBeltPrintingPlate + this.subtotal.coverBeltPrinting
    result += this.subtotal.coverBeltProofreading
    return result
  },
  frontCoverPrinting: function () {
    if (typeof this.subtotal.frontCoverPrinting === 'undefined') {
      return ''
    }
    let result = this.subtotal.frontCoverPrintingPlate + this.subtotal.frontCoverPrinting
    result += this.subtotal.frontCoverProofreading
    return result
  },
  mikaeshiPrinting: function () {
    if (typeof this.subtotal.mikaeshiPrinting === 'undefined') {
      return ''
    }
    let result = this.subtotal.mikaeshiPrintingPlate + this.subtotal.mikaeshiPrinting
    result += this.subtotal.mikaeshiProofreading
    return result
  }
})
export default {
  computed: computed
}
</script>
