export function estimateFormsetConvert (form) {
  const keyPair = {
    genre: 'genreId',
    booksize: 'hangataId',
    bookbinding: 'bookbindingId',
    pageCount: 'pageCount',
    copies: 'copies',
    textcolor: 'textcolor',
    // Printing
    bookcover: 'hasBookcover',
    bookcoverPaper: 'bookcoverPaperId',
    coverbelt: 'hasCoverBelt',
    coverbeltPaper: 'coverbeltPaperId',
    frontcoverPaper: 'frontcoverPaperId',
    treatment: 'isPP',
    bodyPaper: 'bodyPaperId',
    mikaeshi: 'hasMikaeshi',
    mikaeshiPaper: 'mikaeshiPaperId',
    hakuoshi: 'isHakuoshi',
    scanCount: 'scanCount',
    // Prepress
    planning: 'planingGrade',
    editing: 'editingGrade',
    proofreading: 'proofreadingGrade',
    binding: 'bindingGrade',
    writing: 'isWriting',
    rewriting: 'isRewriting',
    // Sales
    distribution: 'distributionPlan',
    distributionPackage: 'promotionPlan'
  }

  const result = {}
  for (const from in keyPair) {
    const to = keyPair[from]
    result[to] = form[from]
  }
  return result
}
