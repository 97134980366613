<template>
  <div class="autoprice__confirm-table">

    <!-- アンケート -->

    <h2 class="autoprice__confirm-tablehead">アンケートの回答を確認する</h2>

    <div class="autoprice__confirm-item">
      <label>デザインについて</label>
      <em><template v-for="(item, index) in surveyDesign"><template v-if="index > 0"><br></template>
        ・{{ item }}</template>
      </em>
    </div>
    <div class="autoprice__confirm-item">
      <label>編集について</label>
      <em><template v-for="(item, index) in surveyEdit"><template v-if="index > 0"><br></template>
        ・{{ item }}</template>
      </em>
    </div>
    <div class="autoprice__confirm-item">
      <label>書店流通について</label>
      <em><template v-for="(item, index) in surveyDistribution"><template v-if="index > 0"><br></template>
        ・{{ item }}</template>
      </em>
    </div>
    <div class="autoprice__confirm-item">
      <label>販売PRについて</label>
      <em><template v-for="(item, index) in surveySalesPR"><template v-if="index > 0"><br></template>
        ・{{ item }}</template>
      </em>
    </div>

  </div>
</template>

<script>
import formData from '../../store/modules/formstructs/questionary.js'

const dataKeys = []
for (const type in formData) {
  for (const key in formData[type]) {
    dataKeys.push(key)
  }
}

const computed = {}

Object.assign(computed, Vuex.mapState('questionary', dataKeys))

export default {
  computed: computed
}
</script>
