import common from './modules/common.js'
import estimate from './modules/estimate.js'
import bookdata from './modules/bookdata.js'
import questionary from './modules/questionary.js'

export default new Vuex.Store({
  modules: {
    common: common,
    estimate: estimate,
    bookdata: bookdata,
    questionary: questionary
  }
})
