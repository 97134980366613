import genreItems from './genreItems.js'
import bookbindingItems from './bookbindingItems.js'

export default () => ({
  genreItems: genreItems,
  bookbindingItems: bookbindingItems,
  receiveCopyItems: [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000,
    1100,
    1200,
    1300,
    1400,
    1500
  ],
  textcolorItems: [
    { id: 'text-mono', name: 'モノクロ', value: 'mono' },
    { id: 'text-color', name: 'カラー', value: 'color' }
  ]
})
