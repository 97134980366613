export function calcBodyPages (
  charsPerLine,
  linesPerPage,
  pages
) {
  const params = charsPerLine * linesPerPage * pages
  if (params < 600) {
    throw new RangeError('Too Little Numbers')
  }

  const result = (params / 600) * 1.1
  // 少数第一位まで考慮
  if (result % 1 > 0.09) {
    return Math.ceil(result)
  }
  // 少数切り捨て
  return parseInt(result.toFixed(1))
}
