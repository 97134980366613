import { createGetterSetter } from '../../common/createComputed.js'
import booksizeItems from '../data/booksizeItems.js'
import circulationItems from '../data/circulationItems.js'

const pageEtcProperties = {
  booksize: false,
  pageCount: true,
  copies: true,
  receiveCopies: true
}

const items = {}
for (const index in pageEtcProperties) {
  const isInt = pageEtcProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

items['bodyColorOnlyGenres'] = function () {
  return this.genreItems.filter(item => item.isBodyColorOnly)
}

items['genre'] = {
  get () {
    return this.$store.state['estimate']['genre']
  },
  set (value) {
    const data = {
      key: 'genre',
      value: value
    }
    this.$store.commit('estimate/updateState', data)

    const papers = [
      'bookcoverPaper',
      'frontcoverPaper',
      'mikaeshiPaper',
      'bodyPaper',
      'booksize'
    ]
    // ジャンルを変えると選び直す必要があるため
    for (const index in papers) {
      const data = {
        key: papers[index],
        value: null
      }
      this.$store.commit('estimate/updateState', data)
    }

    // カラーしか選べないジャンルのため
    for (const index in this.bodyColorOnlyGenres) {
      const target = this.bodyColorOnlyGenres[index]
      if (target.id === value) {
        const data = {
          key: 'textcolor',
          value: 'color'
        }
        this.$store.commit('estimate/updateState', data)
        break
      }
    }

    // 絵本特有の見返し仕様のため
    if (value === 'picture-book' && this.$store.state['estimate']['bookbinding'] === 'hard') {
      const data = {
        key: 'mikaeshi',
        value: true
      }
      this.$store.commit('estimate/updateState', data)
    } else if (value !== 'picture-book') {
      const data = {
        key: 'mikaeshi',
        value: false
      }
      this.$store.commit('estimate/updateState', data)
    }

    if (value === 'picture-book') {
      // 絵本時選択肢変更のため
      // 選択中のものがなくなる際初期値設定
      if (this.bookbinding === 'hard') {
        const data = {
          key: 'editing',
          value: 'standard'
        }
        this.$store.commit('estimate/updateState', data)
      }
      if (this.copies < this.circulationItems[0]) {
        const data = {
          key: 'copies',
          value: this.circulationItems[0]
        }
        this.$store.commit('estimate/updateState', data)
      }
      // 絵本の場合リライトのデフォルトを設定する
      const data = {
        key: 'rewriting',
        value: false
      }
      this.$store.commit('estimate/updateState', data)
    }
  }
}

items['isBodyColorOnly'] = function () {
  const targets = this.bodyColorOnlyGenres
  for (const inx in targets) {
    const genre = targets[inx]
    if (genre.id === this.genre) {
      return true
    }
  }

  return false
}

items['textcolor'] = {
  get () {
    return this.$store.state['estimate']['textcolor']
  },
  set (value) {
    const data = {
      key: 'textcolor',
      value: value
    }
    this.$store.commit('estimate/updateState', data)

    const papers = [
      'bodyPaper'
    ]
    // 選び直す必要があるため
    for (const index in papers) {
      const data = {
        key: papers[index],
        value: null
      }
      this.$store.commit('estimate/updateState', data)
    }
  }
}

items['bookbinding'] = {
  get () {
    return this.$store.state['estimate']['bookbinding']
  },
  set (value) {
    const data = {
      key: 'bookbinding',
      value: value
    }
    this.$store.commit('estimate/updateState', data)

    const papers = [
      'frontcoverPaper'
    ]
    // 選び直す必要があるため
    for (const index in papers) {
      const data = {
        key: papers[index],
        value: null
      }
      this.$store.commit('estimate/updateState', data)
    }

    // 絵本の強制仕様
    if (value === 'hard' && this.$store.state['estimate']['genre'] === 'picture-book') {
      let data = {
        key: 'mikaeshi',
        value: true
      }
      this.$store.commit('estimate/updateState', data)

      data = {
        key: 'editing',
        value: 'standard'
      }
      this.$store.commit('estimate/updateState', data)

      if (this.copies < this.circulationItems[0]) {
        data = {
          key: 'copies',
          value: this.circulationItems[0]
        }
        this.$store.commit('estimate/updateState', data)
      }
    }
  }
}

items['maxPageCount'] = function () {
  if (this.genre === 'picture-book') {
    return 80
  }

  return 448
}

items['booksizeItems'] = function () {
  if (this.genre === 'picture-book') {
    return booksizeItems.filter(item => item.id !== 'size-A6')
  }

  return booksizeItems
}

items['circulationItems'] = function () {
  if (
    this.genre === 'picture-book' &&
    this.bookbinding === 'hard'
  ) {
    return circulationItems.filter(val => val >= 300)
  }
  return circulationItems
}

export default items
