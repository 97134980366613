export default [
  200,
  300,
  400,
  500,
  600,
  800,
  1000,
  1500,
  2000
]
