import { createGetterSetter } from '../../common/createComputed.js'

const pageProperties = {
  printingProofreading: false,
  hakuoshi: false,
  scanCount: true
}

const items = {}
for (const index in pageProperties) {
  const isInt = pageProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

export const computed = items
