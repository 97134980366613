import bookbasicItems from '../../../components/estimate/bookbasic/data/index.js'
import bookprintingItems from '../../../components/estimate/bookprinting/data/index.js'
import prepressItems from '../../../components/estimate/prepress/data/index.js'
import creatingstateItems from '../../../components/estimate/creatingstate/data/index.js'
import salesItems from '../../../components/estimate/sales/data/index.js'
import optionItems from '../../../components/estimate/option/data/index.js'

export function formsetWithLabel (state, getters, rootState, rootGetters) {
  const formset = getters.getFormset
  const result = new Map()

  const items = {
    bookbasic: bookbasicItems(),
    printing: bookprintingItems(),
    prepress: prepressItems(),
    creatingstate: creatingstateItems(),
    sales: salesItems(),
    option: optionItems()
  }

  const idKeys = [
    ['genre', items.bookbasic.genreItems]
  ]

  for (const keyIndex in idKeys) {
    const krow = idKeys[keyIndex]
    const key = krow[0]
    const items = krow[1]
    const item = searchItem(items, (row) => {
      return formset[key] === row.id
    })
    result.set(key, item.name)
  }

  const selectKeys = [
    ['booksize', items.bookbasic.booksizeItems],
    ['bookbinding', items.bookbasic.bookbindingItems],
    ['textcolor', items.bookbasic.textcolorItems],
    ['bookcover', items.printing.bookcoverItems],
    ['coverbelt', items.printing.coverbeltItems],
    ['treatment', items.printing.treatmentItems],
    ['mikaeshi', items.printing.mikaeshiItems],
    ['planning', items.prepress.planningItems],
    ['editing', items.prepress.editingItems],
    ['writing', items.prepress.writingItems],
    ['rewriting', items.prepress.rewritingItems],
    ['proofreading', items.prepress.proofreadingItems],
    ['binding', items.creatingstate.bindingItems],
    ['distribution', items.sales.distributionItems],
    ['distributionPackage', items.sales.distributionPackageItems],
    ['hakuoshi', items.option.hakuoshiItems]
  ]

  for (const keyIndex in selectKeys) {
    const krow = selectKeys[keyIndex]
    const key = krow[0]
    const items = krow[1]
    const item = searchItem(items, (row) => {
      return formset[key] === row.value
    })
    result.set(key, item.name)
  }

  const paperIdKeys = [
    ['bookcoverPaper', getters.coverPaperItems],
    ['frontcoverPaper', getters.frontCoverPaperItems],
    ['bodyPaper', getters.bodyPaperItems],
    ['mikaeshiPaper', getters.mikaeshiPaperItems]
  ]

  for (const keyIndex in paperIdKeys) {
    const krow = paperIdKeys[keyIndex]
    const key = krow[0]
    const items = krow[1]
    const item = searchItem(items, (row) => {
      return formset[key] === row.paperId
    })
    result.set(key, item.name)
  }

  for (const key in formset) {
    if (result.has(key) === false) {
      result.set(key, formset[key])
    }
  }

  const data = {}
  result.forEach((value, key) => {
    data[key] = value
  })
  return data
}

function searchItem (items, validator) {
  const result = ''
  for (const i in items) {
    const row = items[i]
    if (validator(row)) {
      return row
    }
  }

  return result
}
