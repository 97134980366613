import { estimateFormsetConvert } from '../../model/estimateFormsetConvert'

export default {
  methods: {
    next: function (event) {
      const isValid = this.fireValidate()
      if (isValid === false) {
        return
      }

      const preState = this.$store.state.estimate.status
      this.$store.commit('estimate/blockNext')
      if (preState === this.$store.state.estimate.status) {
        const formset = this.$store.getters['estimate/getFormset']
        const estimateFormset = estimateFormsetConvert(formset)
        this.$store.state.common.calc.setFormset(estimateFormset)
        const reciept = this.$store.state.common.calc.calcSubTotal()
        this.$store.commit('estimate/updateReciept', reciept)
        this.$store.commit('common/formNext')
      }
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    },
    back: function (event) {
      const preState = this.$store.state.estimate.status
      this.$store.commit('estimate/blockBack')
      if (preState === this.$store.state.estimate.status) {
        return
      }
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    },
    fireValidate: function () {
      const form = document.querySelector('#form-estimate')
      return form.reportValidity()
    }
  }
}
