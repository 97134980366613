export default () => ({
  distributionItems: [
    { id: 'distribution-bookstore', name: '流通　ミニマム', value: 'bookstore', desc: '・Amazon登録<br>・当社サイトに掲載<br>・当社ECサイトでの販売' },
    { id: 'distribution-sales-consig', name: '流通　ベーシック', value: 'cosignwithsales', desc: 'ベーシックとは 流通ミニマム ＋ 流通パッケージ です。<br>流通パッケージ の中から１つお選びください。' }
  ],
  distributionPackageItems: [
    { id: 'distribution_package-C', name: '「ベーシック １」パッケージ', value: 'plan-c', desc: ['チラシ作成（合同）', '書店営業（３〜５店舗）'] },
    { id: 'distribution_package-A', name: '「ベーシック ２」パッケージ', value: 'plan-a', desc: ['チラシ作成（合同）', '書店営業（７〜１０店舗）', '電話営業', 'FAX営業'] },
    { id: 'distribution_package-B', name: '「ベーシック + チャレンジ」パッケージ', value: 'plan-b', desc: ['POP作成', 'チラシ作成（オリジナル）', '書店営業（２０〜５０店舗)', '電話営業', 'FAX営業'] }
  ]
})
