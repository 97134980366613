import formStructs from './formstructs/estimate.js'
import { formsetWithLabel } from './estimate/formsetWithLabel.js'

const BLOCK_STATE = [
  'bookbasic',
  'bookpringting',
  'prepress',
  'creatingstate',
  'sales',
  'option'
]

const formData = {}
for (const key in formStructs) {
  Object.assign(formData, formStructs[key])
}

const state = () => (formData)

const masterPapersByRels = function (datatable, rels) {
  const result = datatable.filter(row => {
    for (const index in rels) {
      if (row.paperId === rels[index].paperId) {
        return true
      }
    }
    return false
  })
  return result
}

const getters = {
  blockState: (state) => {
    const labels = BLOCK_STATE

    const current = state.status
    if (typeof labels[current] === 'undefined') {
      return null
    }
    return labels[current]
  },
  coverPaperItems: function (state, getters, rootState, rootGetters) {
    const genreId = state.genre
    const partsType = 'cover'
    const reltable = rootState.common.master.relpaper
    const rels = reltable.filter(row => {
      return row.genreId === genreId && row.parts === partsType && row.hangataId === state.booksize
    })
    const master = rootState.common.master.paper
    const result = masterPapersByRels(master, rels)

    return result
  },
  frontCoverPaperItems: function (state, getters, rootState, rootGetters) {
    const genreId = state.genre
    const bindingType = state.bookbinding
    const partsType = 'frontcover-' + bindingType
    const reltable = rootState.common.master.relpaper
    const rels = reltable.filter(row => {
      return row.genreId === genreId && row.parts === partsType && row.hangataId === state.booksize
    })
    const master = rootState.common.master.paper
    const result = masterPapersByRels(master, rels)

    return result
  },
  bodyPaperItems: function (state, getters, rootState, rootGetters) {
    const genreId = state.genre
    const colorType = state.textcolor === 'color' ? '4c' : 'bw'
    const partsType = 'body-' + colorType
    const reltable = rootState.common.master.relpaper
    const rels = reltable.filter(row => {
      return row.genreId === genreId && row.parts === partsType && row.hangataId === state.booksize
    })
    const master = rootState.common.master.paper
    const result = masterPapersByRels(master, rels)

    return result
  },
  mikaeshiPaperItems: function (state, getters, rootState, rootGetters) {
    const genreId = state.genre
    const partsType = 'mikaeshi'
    const reltable = rootState.common.master.relpaper
    const rels = reltable.filter(row => {
      return row.genreId === genreId && row.parts === partsType && row.hangataId === state.booksize
    })
    const master = rootState.common.master.paper
    const result = masterPapersByRels(master, rels)

    return result
  },
  coverbeltPaperItems: function (state, getters, rootState, rootGetters) {
    const genreId = state.genre
    const partsType = 'coverbelt'
    const reltable = rootState.common.master.relpaper
    const rels = reltable.filter(row => {
      return row.genreId === genreId && row.parts === partsType && row.hangataId === state.booksize
    })
    const master = rootState.common.master.paper
    const result = masterPapersByRels(master, rels)

    return result
  },
  seisakugokei: function (state, getters, rootState, rootGetters) {
    const reciepts = state.reciept
    let total = null
    if (reciepts.length > 0) {
      total = reciepts.reduce((sum, current) => {
        const res = sum + current.value
        return res
      }, 0)
    }

    return total
  },
  kakakukoutoufee: function (state, getters, rootState, rootGetters) {
    const gassan = getters.seisakugokei
    if (gassan === null) {
      return null
    }
    const result = gassan * 0.25
    // 少数切り捨て
    return parseInt(result.toFixed(1))
  },
  taxtarget: function (state, getters, rootState, rootGetters) {
    let result = 0
    const total = [
      getters.seisakugokei,
      getters.kakakukoutoufee
    ]
    for (const index in total) {
      const row = total[index]
      if (row === null) {
        continue
      }
      result += row
    }

    return result
  },
  tax: function () {
    // %
    return 10
  },
  taxfee: function (state, getters, rootState, rootGetters) {
    const result = getters.taxtarget * getters.tax / 100
    return parseInt(result.toFixed(1))
  },
  getFormset: function (state) {
    const formset = []
    for (const key in state) {
      if (key === 'status' || key === 'reciept') {
        continue
      }
      formset[key] = state[key]
    }

    return formset
  },
  getFormsetWithLabel: formsetWithLabel
}

const mutations = {
  blockNext (state) {
    const current = state.status
    const next = current + 1
    if (typeof BLOCK_STATE[next] === 'undefined') {
      return null
    }

    state.status = next
  },
  blockBack (state) {
    const current = state.status
    const preview = current - 1
    if (typeof BLOCK_STATE[preview] === 'undefined') {
      return null
    }

    state.status = preview
  },
  updateState (state, payload) {
    const key = payload.key
    if (typeof state[key] === 'undefined') {
      throw new Error('NotFound state: ' + key)
    }
    state[key] = payload.value
    if (this.state.common.environment !== 'production') {
      console.log(payload)
    }
  },
  updateReciept (state, reciept) {
    state.reciept = reciept
    if (this.state.common.environment !== 'production') {
      console.log(reciept)
    }
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations
}
