<template>
<section class="form__bookdatas">
  <form action="/my-handling-form-page" method="post" @submit.prevent id="form-bookdata">

    <div class="form__bookdata">

      <h2 class="form-block_h2 form__bookdata-title">書籍＆著者情報の入力</h2>

      <section class="bookdata__entrys">

        <!-- 書籍＆個人情報 -->

        <dl class="bookdata__entry">

          <dt class="bookdata__question">
            <label for="booktitle">書籍名<span>必須</span></label>
          </dt>
          <dd class="bookdata__anser">
            <input type="text" id="booktitle" class="width-w" placeholder="書籍名" v-model="booktitle" required>
          </dd>

          <dt class="bookdata__question">
            <label for="penname">著者名<small>※ペンネームを利用の場合</small></label>
          </dt>
          <dd class="bookdata__anser">
            <input type="text" id="penname" class="width-w" placeholder="※ペンネームを記入" v-model="penname">
          </dd>

          <dt class="bookdata__question">
            <label for="pennameRuby">著者名フリガナ</label>
          </dt>
          <dd class="bookdata__anser">
            <input type="text" id="pennameRuby" class="width-w" placeholder="※カタカナで入力" v-model="pennameRuby">
          </dd>

          <dt class="bookdata__question">
            <div>名前<span>必須</span></div>
          </dt>
          <dd class="bookdata__anser input-inline">
            <span>
              <label for="nameLast">姓</label>
              <input type="text" name="namefamily" id="namefamily" class="namae" v-model="namefamily" required>
            </span>
            <span>
              <label for="nameFirst">名</label>
              <input type="text" name="name" id="name" class="namae" v-model="name" required>
            </span>
          </dd>

          <dt class="bookdata__question">
            <div>フリガナ<span>必須</span></div>
          </dt>
          <dd class="bookdata__anser input-inline">
            <span>
              <label for="kanaLast">セイ</label>
              <input type="text" name="kanafamily" id="kanafamily" class="kana" v-model="kanafamily" required>
            </span>
            <span>
              <label for="kanaFirst">メイ</label>
              <input type="text" name="kananame" id="kananame" class="kana" v-model="kananame" required>
            </span>
          </dd>

          <dt class="bookdata__question">
            <label for="email">メールアドレス<span>必須</span></label>
          </dt>
          <dd class="bookdata__anser">
            <input type="email" id="email" class="width-w" placeholder="acount@momongapress.com" v-model="email" required>
          </dd>

          <dt class="bookdata__question">
            <label for="emailConfirm">メールアドレス:確認<span>必須</span></label>
          </dt>
          <dd class="bookdata__anser">
            <input type="text" id="emailConfirm" class="width-w" :pattern="email" required>
          </dd>

          <dt class="bookdata__question">
            <label for="emailConfirm">性別</label>
          </dt>
          <dd class="bookdata__anser">
            <template v-for="(item, index) in genderItems">
            <input type="radio" name="gender" :id="'gender_' + index" :value="item.name" v-model="gender">
              <label :for="'gender_' + index">{{ item.name }}</label>
            </template>
          </dd>

          <dt class="bookdata__question">
            <label for="age">年齢<span>必須</span></label>
          </dt>

          <dd class="bookdata__anser input-inline">
            <span v-for="(item, index) in ageItems">
              <input type="radio" name="age" :id="'age_' + index" :value="item.name" v-model="age" required>
              <label :for="'age_' + index">{{ item.name }}</label>
            </span>
          </dd>

          <dt class="bookdata__question">
            <label for="infomail">今後、弊社発信の情報メールをお送りしてもよろしいですか？<span>必須</span></label>
          </dt>
          <dd class="bookdata__anser">
            <label v-for="item in infomailItems"><input type="radio" name="infomail" :value="item.name" v-model="infomail" required>{{ item.name }}</label>
          </dd>

          <dt class="bookdata__question">
            <label for="note">ご質問など</label>
          </dt>
          <dd class="bookdata__anser">
            <textarea id="note" rows="10" cols="60" placeholder="ここに記入してください" v-model="note"></textarea>
          </dd>

        </dl>

        <div class="bookdata__note">※文面リライト必要　入力していただいた個人情報はご本人の同意がない限り第三者には提供いたしません。</div>

      </section>

      <aside class="block_aside_form block-a_aside_form">
        <div class="block-a_aside_form-btm block_form-btm submit">
          <button type="button" name="pagecalculation" @click="next">
            <strong>書籍＆著者情報を確認する</strong>
          </button>
        </div>
      </aside>

    </div>
    <showphase></showphase>
  </form>
</section>
</template>

<script>
import Showphase from './common/Showphase.vue'
import { createGetterSetter } from './estimate/common/createComputed.js'
const formdata = {
  booktitle: false,
  penname: false,
  pennameRuby: false,
  namefamily: false,
  name: false,
  kanafamily: false,
  kananame: false,
  email: false,
  gender: false,
  age: false,
  infomail: false,
  note: false
}

const computed = {}
for (const key in formdata) {
  computed[key] = createGetterSetter('bookdata', key, formdata[key])
}

export default {
  data: () => ({
    genderItems: [
      { id: 'male', name: '男性' },
      { id: 'female', name: '女性' }
    ],
    ageItems: [
      { id: '10', name: '19歳以下' },
      { id: '20', name: '20～29歳' },
      { id: '30', name: '30～39歳' },
      { id: '40', name: '40～49歳' },
      { id: '50', name: '50～59歳' },
      { id: '60', name: '60～69歳' },
      { id: '70', name: '70歳以上' }
    ],
    infomailItems: [
      { id: 'yes', name: 'はい' },
      { id: 'no', name: 'いいえ' }
    ]
  }),
  computed: computed,
  components: {
    showphase: Showphase
  },
  methods: {
    back: function () {
      this.$store.commit('common/formBack')
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    },
    next: function () {
      const isValid = this.fireValidate()
      if (isValid === false) {
        return
      }
      this.$store.commit('common/formNext')
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    },
    fireValidate: function () {
      const form = document.querySelector('#form-bookdata')
      const mailconfirm = document.querySelector('#emailConfirm')
      mailconfirm.setCustomValidity('')
      if (mailconfirm.validity.patternMismatch) {
        mailconfirm.setCustomValidity('メールアドレスと一致しません。ご確認ください。')
      }

      return form.reportValidity()
    }
  }
}
</script>
