export default () => ({
  datatypeItems: [
    { id: 'datatype-freehand', name: '手書き原稿', memo: '', sup: '1' },
    { id: 'datatype-word', name: 'Wordなどのデータ原稿', memo: '', sup: '' },
    { id: 'datatype-completed', name: '完全データ原稿', memo: '（レイアウト完成済）', sup: '' }
  ],
  bindingItems: [
    { id: 'binding-simple', name: '簡易的な装丁を依頼', value: 'simple', memo: '（弊社にお任せ）' },
    { id: 'binding-standard', name: '本格的な装丁を依頼', value: 'standard', memo: '（イラスト費などの費用が発生します）' }
  ]
})
