<template>
  <section class="form__bookdatas">
    <form action="/my-handling-form-page" method="post" @submit.prevent>

      <div class="block-bookdata">

        <h2 class="form-block_h2 form__bookdata-title">アンケートを入力する</h2>

        <section class="bookdata__entrys">

          <!-- アンケート -->

          <dl class="bookdata__entry">

            <dt class="bookdata__question">
              <div>デザインについて</div>
            </dt>
            <dd class="bookdata__anser">
              <span v-for="(item, index) in surveyDesignItems">
                <label><input type="checkbox" name="surveyDesign" :id="'surveyDesign_' + index" :value="item.name" v-model="surveyDesign">
                {{ item.name }}
                </label>
              </span>
            </dd>

            <dt class="bookdata__question">
              <div>編集について</div>
            </dt>
            <dd class="bookdata__anser">
              <span v-for="(item, index) in surveyEditItems">
                <label><input type="checkbox" name="surveyEdit" :value="item.name" v-model="surveyEdit">
                {{ item.name }}
              </label>
              </span>
            </dd>

            <dt class="bookdata__question">
              <div>書店流通について</div>
            </dt>
            <dd class="bookdata__anser">
              <span v-for="(item, index) in surveyDistributionItems">
                <label><input type="checkbox" name="surveyDistribution" :value="item.name" v-model="surveyDistribution">
                {{ item.name }}
                </label>
              </span>
            </dd>

            <dt class="bookdata__question">
              <div>販売PRについて</div>
            </dt>
            <dd class="bookdata__anser">
              <span v-for="(item, index) in surveySalesPRItems">
                <label><input type="checkbox" name="surveySalesPR" :value="item.name" v-model="surveySalesPR">
                {{ item.name }}
                </label>
              </span>
            </dd>

          </dl>

        </section>

        <aside class="block_aside_form block-a_aside_form">
          <div class="block-a_aside_form-btm block_form-btm submit">
            <button type="button" name="pagecalculation" @click="next">
              <strong>書籍＆著者情報＆アンケートを確認する</strong>
            </button>
          </div>
        </aside>

      </div>
      <showphase></showphase>
    </form>
  </section>
</template>

<script>
import { createGetterSetter } from './estimate/common/createComputed.js'
import Showphase from './common/Showphase.vue'
const formdata = {
  surveyDesign: false,
  surveyEdit: false,
  surveyDistribution: false,
  surveySalesPR: false
}

const computed = {}
for (const key in formdata) {
  computed[key] = createGetterSetter('questionary', key, formdata[key])
}

export default {
  data: () => ({
    surveyDesignItems: [
      { id: '1', name: '手書きやプリントアウトの原稿しかなく、テキスト入力を依頼したい。' },
      { id: '2', name: '参考書やハウトゥー本のように、特殊なレイアウトの本をつくりたい。' },
      { id: '3', name: '本文中に使うイラスト・図表などの制作を依頼したい。' },
      { id: '4', name: '写真やイラストを10点以上掲載したい（10点までは標準価格で対応します）。' },
      { id: '5', name: '（文芸書をご希望の方へ）カラーページを追加したい。' },
      { id: '6', name: '（本格・小部数タイプの方へ）表紙デザインにあわせたオリジナルの帯を付けたい。' },
      { id: '7', name: '（小部数タイプの方へ）オリジナルの表紙カバーを制作してほしい。' },
      { id: '8', name: '定形外のサイズや、箔押しなどの特殊加工・特殊印刷など相談にのってほしい。' }
    ],
    surveyEditItems: [
      { id: '1', name: 'プロのライターに原稿を書いてもらいたい。' },
      { id: '2', name: '自分で書いた原稿をプロのライターに手直ししてもらいたい。' },
      { id: '3', name: '自分で書いた原稿の誤字脱字や文章表現のチェックなどを依頼したい。' },
      { id: '4', name: '原稿は自分で書く予定だが、本の全体構成などをプロの編集者に監修してもらいたい。' }
    ],
    surveyDistributionItems: [
      { id: '1', name: '全国書店、大手インターネット書店、図書館などから注文を受けたい。' },
      { id: '2', name: '出版と同時に書店の店頭に並べてもらえるようにしたい。' },
      { id: '3', name: 'インターネット書店「アマゾン」だけで販売したい。' }
    ],
    surveySalesPRItems: [
      { id: '1', name: 'チラシを制作してほしい。' },
      { id: '2', name: '書店への営業活動を依頼したい。' },
      { id: '3', name: 'FAXで全国の書店やマスコミに宣伝を行いたい。' },
      { id: '4', name: '新聞や雑誌に広告を掲載したい。' },
      { id: '5', name: 'POPやポスターなど、書店の販促ツールを制作してもらいたい。' },
      { id: '6', name: '大手書店でPRしたい。' }
    ]
  }),
  computed: computed,
  components: {
    showphase: Showphase
  },
  methods: {
    back: function () {
      this.$store.commit('common/formBack')
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    },
    next: function () {
      this.$store.commit('common/formNext')
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    }
  }
}
</script>
