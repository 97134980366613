export default [
  { id: 'poem', name: '詩集', isBodyColorOnly: false },
  { id: 'tanka', name: '短歌', isBodyColorOnly: false },
  { id: 'haiku', name: '俳句', isBodyColorOnly: false },
  { id: 'senryu', name: '川柳', isBodyColorOnly: false },
  { id: 'novel', name: '小説', isBodyColorOnly: false },
  { id: 'light-novels', name: 'ライトノベルズ', isBodyColorOnly: false },
  { id: 'history-novel', name: '歴史・時代小説', isBodyColorOnly: false },
  { id: 'twilight', name: 'ミステリー', isBodyColorOnly: false },
  { id: 'criticism', name: '評論・文学研究', isBodyColorOnly: false },
  { id: 'essay', name: 'エッセイ・随筆', isBodyColorOnly: false },
  { id: 'business', name: 'ビジネス', isBodyColorOnly: false },
  { id: 'administration', name: '実用書', isBodyColorOnly: false },
  { id: 'autobiography', name: '人物自伝', isBodyColorOnly: false },
  { id: 'management', name: '旅行・くらし・趣味', isBodyColorOnly: false },
  { id: 'picture-book', name: '絵本', isBodyColorOnly: true },
  { id: 'photo-album', name: '写真', isBodyColorOnly: true },
  { id: 'art', name: 'アート', isBodyColorOnly: true },
  { id: 'visual-book', name: 'ビジュアルブック', isBodyColorOnly: true },
  { id: 'collection', name: '作品集', isBodyColorOnly: true }
]
