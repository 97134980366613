export default {
  form: {
    status: 0
  },
  bookdata: {
    booktitle: '',
    penname: '',
    pennameRuby: '',
    namefamily: '',
    name: '',
    kanafamily: '',
    kananame: '',
    email: '',
    gender: '',
    age: '',
    infomail: '',
    note: ''
  }
}
