import { createGetterSetter } from '../../common/createComputed.js'

const pageProperties = {
  bookcover: false,
  bookcoverPaper: false,
  coverbelt: false,
  frontcoverPaper: false,
  treatment: false,
  textcolor: false,
  bodyPaper: false,
  mikaeshi: false,
  mikaeshiPaper: false
}

const items = {}
for (const index in pageProperties) {
  const isInt = pageProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

const paperItems = [
  'coverPaperItems',
  'frontCoverPaperItems',
  'bodyPaperItems',
  'mikaeshiPaperItems'
]
for (const index in paperItems) {
  const key = paperItems[index]
  items[key] = function (context) {
    return context.$store.getters['estimate/' + key]
  }
}

items['mikaeshi'] = {
  get () {
    return this.$store.state['estimate']['mikaeshi']
  },
  set (value) {
    if (value === false) {
      const data = {
        key: 'mikaeshiPaper',
        value: null
      }
      this.$store.commit('estimate/updateState', data)
    }

    const data = {
      key: 'mikaeshi',
      value: value
    }
    this.$store.commit('estimate/updateState', data)
  }
}

items['canMikaeshi'] = (context) => {
  const genre = context.$store.state.estimate.genre
  return genre === 'picture-book'
}

items['mikaeshiBookcoverCSS'] = (context) => {
  const bookbinding = context.$store.state.estimate.bookbinding
  if (bookbinding) {
    return bookbinding + 'cover'
  }
  return ''
}

items['forceEnableMikaeshi'] = (context) => {
  const genre = context.$store.state.estimate.genre
  const bookbinding = context.$store.state.estimate.bookbinding
  return genre === 'picture-book' && bookbinding === 'hard'
}

items['coverbelt'] = {
  get () {
    return this.$store.state['estimate']['coverbelt']
  },
  set (value) {
    if (value) {
      const coverbeltPaperItems = this.$store.getters['estimate/coverbeltPaperItems']
      if (coverbeltPaperItems.length === 0) {
        throw new Error('coverbeltPapers are Empty')
      }

      const data = {
        key: 'coverbeltPaper',
        value: coverbeltPaperItems[0].paperId
      }
      this.$store.commit('estimate/updateState', data)
    }

    const data = {
      key: 'coverbelt',
      value: value
    }
    this.$store.commit('estimate/updateState', data)
  }
}

items['environment'] = (context) => {
  const environment = context.$store.state.common.environment
  return environment
}

export const computed = items
