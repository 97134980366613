export default () => ({
  bookfeatureItems: [
    { id: 'feature-text', name: '文字メイン' },
    { id: 'feature-visual', name: 'ビジュアルメイン' },
    { id: 'feature-textvisual', name: '文字 + 補足ビジュアル' },
    { id: 'feature-design', name: '文字 + ビジュアルデザイン重視' }
  ],
  progressItems: [
    { id: 'progress-planning', name: 'アイディアはあり企画段階' },
    { id: 'progress-starting', name: '執筆をはじめている' },
    { id: 'progress-nearlyend', name: '執筆は概ね出来ている' },
    { id: 'progress-done', name: '執筆は終了している' },
    { id: 'progress-completeddata', name: '入稿データまで完成している' }
  ],
  planningItems: [
    { id: 'planning-simple', name: '簡易企画を依頼', value: 'simple', memo: '' },
    { id: 'planning-standard', name: '本格企画を依頼', value: 'standard', memo: '' },
    { id: 'planning-none', name: '企画の依頼はしない', value: '', memo: '（すでに企画がある場合を含む）' }
  ],
  editingItems: [
    { id: 'editing-simple', name: '簡易編集を依頼', value: 'simple', memo: '' },
    { id: 'editing-standard', name: '本格編集を依頼', value: 'standard', memo: '（複数回のやり取り）' }
  ],
  writingItems: [
    { id: 'writing-standard', name: 'ライティングを依頼する', value: true },
    { id: 'writing-none', name: 'ライティングは必要ない', value: false }
  ],
  rewritingItems: [
    { id: 'rewriting-standard', name: 'リライトを依頼する', value: true },
    { id: 'rewriting-none', name: 'リライトは必要ない', value: false }
  ],
  proofreadingItems: [
    { id: 'proofreading-simple', name: '簡易校正', value: 'simple', memo: '' },
    { id: 'proofreading-standard', name: '本格校正', value: 'standard', memo: '（本格校正をお選びください）' }
  ]
})
