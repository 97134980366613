import formStructs from './formstructs/bookdata.js'

const formData = {}
for (const key in formStructs) {
  Object.assign(formData, formStructs[key])
}

const state = () => (formData)

const getters = {
}

const mutations = {
  updateState (state, payload) {
    const key = payload.key
    if (typeof state[key] === 'undefined') {
      throw new Error('NotFound state: ' + key)
    }
    state[key] = payload.value
    console.log(payload)
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations
}
