import { createGetterSetter } from '../../common/createComputed.js'

const pageCalcProperties = {
  charsPerLine: true,
  linesPerPage: true,
  pages: true,
  figurePages: true
}

const pageCalc = {}
for (const index in pageCalcProperties) {
  const isInt = pageCalcProperties[index]
  pageCalc[index] = createGetterSetter('estimate', index, isInt)
}

export default pageCalc
