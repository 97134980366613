import { createGetterSetter } from '../../common/createComputed.js'

const pageProperties = {
  distribution: false,
  distributionPackage: false
}

const items = {}
for (const index in pageProperties) {
  const isInt = pageProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

items['distribution'] = {
  get () {
    return this.$store.state['estimate']['distribution']
  },
  set (value) {
    let data = {
      key: 'distribution',
      value: value
    }
    this.$store.commit('estimate/updateState', data)

    if (value === 'bookstore') {
      data = {
        key: 'distributionPackage',
        value: ''
      }
      this.$store.commit('estimate/updateState', data)
    }
  }
}
items['canSelectPackage'] = (context) => {
  return context.$store.state.estimate.distribution === 'cosignwithsales'
}

export const computed = items
